/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Project.vue?vue&type=template&id=7ce485bf&scoped=true&"
import script from "./Project.vue?vue&type=script&lang=js&"
export * from "./Project.vue?vue&type=script&lang=js&"
import style0 from "./Project.vue?vue&type=style&index=0&id=7ce485bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ce485bf",
  null
  
)

export default component.exports